import styled from 'styled-components';
import variables, { mq, fs, screenSize } from '../../utils/variables';

export const Container = styled.div`
  display: flex;
  justify-content: ${props => props.alignOn === 'left' ? 'flex-start' : 'center'};
  align-items: center;
  margin: 0 auto;
  padding: 100px 5vw 80px;

  ${mq('l')} {
    max-width: 100%;
    padding-left: 3vw;
    padding-right: 3vw;
  }

  ${mq('m')} {
    padding: 40px 35px 30px;
  }

  ${mq('s')} {
    padding: 40px 20px 30px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  /* align-items: flex-start; */
  margin: -30px -36px;
  max-width: 100%;


  ${mq('xxl')} {
    justify-content: flex-start;
  }
  
  ${mq('s')} {
    flex-direction: column;
  }

  .swiper-container {
    width: 100%;
    margin-top: 30px;
    margin-bottom: 50px;
    overflow: visible;
    margin-left: -20px;

    .swiper-wrapper {
      
    }
  }

  .item {
    width: calc((100% / 3) - 72px);
    margin: 30px 36px;

    ${mq('xl')} {
      width: calc(50% - 72px);
    }
    
    ${mq('m')} {
      margin: 0;
      width: calc(100% - 100px);
    }
    
    ${mq('s')} {
      // width: calc(100% - 72px);
    }
  }

  .intro {
    width: calc((100% / 3) - 172px);
    margin: 30px 36px 30px 100px;

    ${mq('l')} {
      width: calc(100% - 172px);
      margin: 30px 36px;
    }

    ${mq('m')} {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
    }

    &-double {
      width: calc((100% / 3 * 2) - 136px);

      ${mq('xl')} {
        width: calc((100%) - 72px);
        margin: 30px 36px;
      }

      ${mq('m')} {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
      }
    }

    &-headline {
      display: block;
      margin-bottom: 25px;
      color: ${variables.colors.blue};
      font-family: 'Bossa', sans-serif;
      font-weight: 700;
      line-height: 1.5;

      ${fs(18)}
    }

    &-title {
      max-width: 27vw;
      margin-bottom: 40px;
      color: ${variables.colors.backgroundDark};
      font-family: 'Bossa', sans-serif;
      font-weight: 500;
      line-height: 1.3;

      ${fs(36)}

      ${mq('xxl')} {
        max-width: 35vw;
      }
      
      ${mq('xl')} {
        max-width: 40vw;
      }
      
      ${mq('l')} {
        max-width: 50vw;
      }

      ${mq('m')} {
        max-width: 75vw;
      }

      ${mq('s')} {
        max-width: 100%;
      }
    }

    &-text {
      color: ${variables.colors.backgroundDark};
      font-family: 'Montserrat', sans-serif;
      font-weight: 500;
      line-height: 1.7;

      ${fs(18)}

      &-cols {
        padding-bottom: 40px;

        ${mq('xl', true)} {
          column-count: 2;
          column-gap: 65px;

          p {
            margin-bottom: 0;
          }
        }
      }
    }

    .link {
      display: inline-flex;
      align-items: center;
      font-family: 'Bossa', sans-serif;
      font-weight: 700;
      line-height: 1.5;
      color: ${variables.colors.red};
      text-decoration: none;

      ${fs(18)}

        svg {
          ${screenSize('width', 18)}
          ${screenSize('height', 8)}
          ${screenSize('margin-left', 10)}

          path {
            fill: ${variables.colors.red};
          }
        }

        &:hover {
          color: ${variables.colors.blue};
          
          svg,
          svg path {
            fill: ${variables.colors.blue};
          }
        }
      }
  }

`;