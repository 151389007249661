import React from 'react';

import { Card } from './styled.js';

const FeatureCard = (props) => {
  return (
    <Card>
      {(props.visual ? (
        <div className="visual">
          <img src={props.visual} alt={`Visual of ${props.headline ? props.headline : 'feature'}`} />
        </div>
      ) : null)}
      <div className="content">
        {(props.headline ? (<span className="headline">{props.headline}</span>) : null)}
        {(props.title ? (<h3 className="title">{props.title}</h3>) : null)}
        {(props.text ? (<p className="text">{props.text}</p>) : null)}
        {(props.url ? (
          <a href={props.url} className="link">
            {props.link}
            <svg width="23" height="10" viewBox="0 0 23 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M16.7053 6.63864V8.60241C16.7053 9.70557 18.0391 10.258 18.8191 9.47798L22.021 6.27614C22.5046 5.79258 22.5045 5.00857 22.021 4.525L18.8191 1.32316C18.0391 0.543113 16.7053 1.09558 16.7053 2.19874V4.16215H1.8464C1.16254 4.16215 0.608154 4.71653 0.608154 5.40039C0.608154 6.08425 1.16254 6.63864 1.8464 6.63864H16.7053Z"/>  
            </svg>
          </a>
        ) : null)}
        <div className="footer">
          {(props.authorAvatar ? (<img className="avatar" src={props.authorAvatar} alt={`Avatar of ${(props.authorName) ? props.authorName : 'author'}`} />) : null)}
          <div className="footer-text">
            {(props.authorName ? (<div className="author">{props.authorName}</div>) : null)}
            {(props.date ? (<div className="date">{props.date}</div>) : null)}
          </div>
        </div>

      </div>
    </Card>
  )
}

export default FeatureCard;
