import PropTypes from "prop-types"
import React from "react"

import { Container, Content } from "./styled.js"

const Hero = props => {
  return (
    <Container {...props}>
      <Content {...props}>{props.children}</Content>
    </Container>
  )
}

Hero.propTypes = {
  dark: PropTypes.bool,
}

Hero.defaultProps = {
  siteTitle: ``,
}

export default Hero
