import React, { useEffect } from 'react';

import sal from 'sal.js'

import { Container, Content } from './styled.js';
import { Button } from '../common/button.js';

const Ready = (props) => {

  useEffect(() => {
    sal();
  }, []);

  return (
    <Container dark={props.dark} reverse={props.reverse}>
      <Content dark={props.dark} reverse={props.reverse}>
        <h2 className="title" data-sal="fade" data-sal-duration="500">Ready to create your first presentation?</h2>
        <div className="button-wrapper-note" data-sal="fade" data-sal-duration="500">
          <Button dark={true} href="https://app.ludus.one/signup">
            Try Ludus for free
          </Button>
          <p className="button-note">
          <svg width="19" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.22476 15.0056C8.22476 15.0056 9.46657 14.8977 9.81618 14.0761C10.1777 13.2259 9.49354 12.8784 9.49354 12.8784C9.49354 12.8784 10.4864 12.909 10.8536 11.8715C11.2001 10.8938 10.3567 10.4451 10.3567 10.4451C10.3567 10.4451 11.3272 10.2858 11.6104 9.25617C11.8485 8.38939 11.0051 7.74359 11.0051 7.74359H17.3376C17.835 7.74359 18.6733 7.46244 18.6738 6.42449C18.6748 5.38706 17.8132 5.15001 17.3376 5.15001H6.70389C6.18517 5.15001 6.18517 4.63129 6.70389 4.63129H8.08679C10.0538 4.63129 11.2733 3.57415 11.6099 3.2043C11.9471 2.83446 12.1177 2.3396 11.9906 1.74152C11.7754 0.726916 10.9947 0.948408 10.3323 1.23578C8.7357 1.9293 6.46061 2.40496 5.38479 2.54968C2.34615 2.95999 0.00311347 5.56291 1.17323e-06 8.71411C-0.00207369 12.1511 2.74816 15.003 6.18517 15.0056H8.22476Z" fill="#FFDC5D"/>
</svg>
 no credit card required
          </p>
        </div>
      </Content>
    </Container>
  );
}

export default Ready;