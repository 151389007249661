import React, { useState } from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

import { Section } from '../components/common/section';

import Hero from '../components/hero';
import FeatureDetail from '../components/feature-detail';
import CommunityCard from '../components/community-card';
import Feature from '../components/feature';
import Ready from '../components/ready';

import illuReady from '../images/illu-ready-reverse.svg';

import visualCardWow from '../images/visual-card-wow.png';
import authorAvatar from '../images/community-card-author-avatar.png';

import featureVisualContent from '../images/visual-wow-community.svg';
import ludusSlideSample from '../images/ludus-slides-sample.png';

const WowHeWorld = () => {
  const [currentBreakPoint, setCurrentBreakpointChildren] = useState({
    type: null,
    bp: null
  });

  return (
    <Layout
      socialDark={true}
      currentPage='wow-he-world'
      updateBreakpoint={setCurrentBreakpointChildren}
    >
      <SEO title="Wow he world" />

      <Section>
        {/* <div style={{
          position: 'absolute',
          zIndex: '0',
          width: '30%',
          height: '0',
          paddingTop: '30%',
          top: '0',
          left: '0',
          background: `transparent url(${illuHeroWow}) no-repeat 0 50%`,
          backgroundSize: 'contain',
        }} />

        <div style={{
          position: 'absolute',
          zIndex: '0',
          width: '30%',
          height: '0',
          paddingTop: '30%',
          bottom: '-28%',
          right: '0',
          background: `transparent url(${illuHero2Wow}) no-repeat 100% 50%`,
          backgroundSize: 'contain',
        }} /> */}

        <Hero>
          <div className="content">
            <h1 className="title">Wow the world</h1>
            <div className="text">
              <p>Ludus is not our baby anymore. It's your baby.</p>
            </div>
          </div>
        </Hero>

      </Section>

      <Section>
        <div style={{
          position: 'absolute',
          zIndex: '1',
          width: '55%',
          paddingBottom: '55%',
          top: '50%',
          left: '0',
          background: `transparent url(${featureVisualContent}) no-repeat 0 50%`,
          backgroundSize: 'contain',
          transform: 'translate(0, -48%)',
        }} />

        <Feature 
          reverse={true}
          headline="The path forward"
          title="Building the best presentation tool is something we can only achieve together."
          text="Our roadmap is public and anyone can contribute to it, so it remains focused on real-world problems."
          link="Roadmap"
          url="https://ludus.kampsite.co/"
        />
      </Section>


      <Section>
        <FeatureDetail>
          <div className="intro">
            <span className="intro-headline">Articles and thoughts</span>
            <h2 className="intro-title">Latest from our blog</h2>
            <div className="intro-text">
              <p>This is where we announce new features, or share our thoughts and views on the beautiful world of content creation.</p>
              <p>
                <a href="https://blog.ludus.one" className="link">
                  Check all our articles
                  <svg width="23" height="10" viewBox="0 0 23 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M16.7053 6.63864V8.60241C16.7053 9.70557 18.0391 10.258 18.8191 9.47798L22.021 6.27614C22.5046 5.79258 22.5045 5.00857 22.021 4.525L18.8191 1.32316C18.0391 0.543113 16.7053 1.09558 16.7053 2.19874V4.16215H1.8464C1.16254 4.16215 0.608154 4.71653 0.608154 5.40039C0.608154 6.08425 1.16254 6.63864 1.8464 6.63864H16.7053Z"/>  
                  </svg>

                </a>
              </p>
            </div>
          </div>
          <div className="item">
            <CommunityCard
              visual={visualCardWow}
              headline="Design"
              title="What happened in September?"
              text="This month, our release’s codename is “Parrot” because we released a few features that will make speakers’ lives easier."
              authorAvatar={authorAvatar}
              authorName="Vincian Bataglala"
              date="Nov 21,2020"
            />
          </div>
          <div className="item">
          <CommunityCard
              visual={visualCardWow}
              headline="Design"
              title="What happened in September?"
              text="This month, our release’s codename is “Parrot” because we released a few features that will make speakers’ lives easier."
              authorAvatar={authorAvatar}
              authorName="Vincian Bataglala"
              date="Nov 21,2020"
            />
          </div>
        </FeatureDetail>
      </Section>

      


      <Section
        dark={true}
      >
        <Feature 
          dark={true}
          reverse={false}
          visual={ludusSlideSample}
          visualType="embed"
          headline="Presentation of the month"
          title="Man bites laniche"
          text="La niche agency is a begian creative agency and decided to use Ludus 6 months ago. This is what they do and some tips "
          url="#content"
          link="Who let the dogs out?"
        />
      </Section>
      <Section
        dark={true}
      >
        <div style={{
          display: (currentBreakPoint.bp === 'm' || currentBreakPoint.bp === 's' || currentBreakPoint.bp === 'xs') ? 'none' : 'block',
          position: 'absolute',
          zIndex: '0',
          width: (currentBreakPoint.bp === 'm' || currentBreakPoint.bp === 's') ? '0%' : '45%',
          paddingBottom: (currentBreakPoint.bp === 'm' || currentBreakPoint.bp === 's') ? '0%' : '45%',
          bottom: '0',
          left: '0',
          background: `transparent url(${illuReady}) no-repeat 0 50%`,
          backgroundSize: 'contain',
        }} />

        <Ready
          dark={true}
          reverse={true}
        />
      </Section>
    </Layout>
  )
};

export default WowHeWorld
