import styled from 'styled-components';
import variables, {fs, screenSize} from '../../utils/variables';

export const Card = styled.div`
  background: ${variables.colors.white} none;
  border-radius: 8px;
  box-shadow: -1px 9px 20px rgba(116, 116, 188, 0.25);

  .visual {
    padding: 50px 50px 0;

    img {
      margin-bottom: 0;
      width: 100%;
    }
  }

  .content {
    padding: 50px;
  }

  .headline {
    font-family: 'Bossa', sans-serif;
    font-weight: 700;
    line-height: 1.5;
    color: ${variables.colors.blue};

    ${fs(14)}
  }

  .title {
    font-family: 'Bossa', sans-serif;
    font-weight: 500;
    line-height: 1.5;
    color: ${variables.colors.backgroundDark};

    ${fs(20)}
  }

  .text {
    margin-bottom: 0;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    line-height: 1.5;
    color: ${variables.colors.greyDarker};

    ${fs(16)}

    p {
      margin-bottom: 0;
    }
  }

  .link {
    font-family: 'Bossa', sans-serif;
    font-weight: 700;
    line-height: 1.5;
    color: ${variables.colors.red};
    text-decoration: none;

    ${fs(16)}

    svg {
      width: 18px;
      height: 8px;
      margin-left: 10px;

      path {
        fill: ${variables.colors.red};
      }
    }

    &:hover {
      color: ${variables.colors.blue};
      
      svg,
      svg path {
        fill: ${variables.colors.blue};
      }
    }
  }

  .footer {
    display: flex;
    margin-top: 60px; 

    .avatar {
      margin: 0 10px 0 0;
      ${screenSize('margin-right', 10)}
      ${screenSize('width', 40)}
      ${screenSize('height', 40)}
    }

    .author {
      font-family: 'Montserrat', sans-serif;
      font-weight: 500;
      line-height: 1.5;
      color: ${variables.colors.backgroundDark};

      ${fs(14)}
    }

    .date {
      font-family: 'Montserrat', sans-serif;
      font-weight: 500;
      line-height: 1.5;
      color: ${variables.colors.grey};

      ${fs(14)}
    }
  }
`;