import styled from 'styled-components';
import variables, { mq, isTouchMedia, fs } from '../../utils/variables';

export const Container = styled.div`
  display: flex;
  justify-content: ${props => props.reverse ? 'flex-end' : 'flex-start'};
  align-items: center;
  margin: 0 auto;
  max-width: 77vw;
  padding: 0 7.32vw;

  ${mq('l')} {
    padding: 0 35px;
  }

  ${mq('m')} {
    padding: 0 20px;
  }

  ${mq('s')} {
    max-width: none;
  }
`;

export const Content = styled.div`
  max-width: 28.65vw;
  padding: 7.29vw 0;
  
  ${mq('m')} {
    max-width: 100%;
    padding: 50px 0;
  }

  .title {
    margin-bottom: 2.4vw;
    font-family: 'Bossa', sans-serif;
    font-weight: 500;
    line-height: 1.2;
    color: ${props => props.dark ? variables.colors.white : variables.colors.backgroundDark};

    ${fs(40)}

    ${mq('m')} {
      margin-bottom: 25px;
    }
  }

  .button {
    display: inline-flex;
    height: 2.8vw;
    padding: 0 1.4vw;
    color: ${variables.colors.white};
    font-family: 'Bossa', sans-serif;
    font-weight: 700;
    line-height: 2.4vw;
    text-decoration: none;
    background: ${variables.colors.red} none;
    border-radius: 2.8vw;
    border: 2px solid ${variables.colors.red};

    ${fs(16)}

    ${mq('m')} {
      height: 60px;
      padding: 0 34px;
      line-height: 58px;
      border-radius: 28px;
    }

    ${isTouchMedia()} {
      &:hover {
        color: ${variables.colors.red};
        background-color: ${variables.colors.white};
        border-color: ${props => props.dark ? variables.colors.white : variables.colors.red}
      }
    }
  }
`;