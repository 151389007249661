import styled from "styled-components"
import variables, {
  mq,
  fs,
  screenSize,
} from "../../utils/variables"

export const Container = styled.div`
  display: flex;
  justify-content: ${props =>
    props.alignOn === "left" ? "flex-start" : "center"};
  align-items: center;
  margin: 0 auto;
  /* max-width: 1480px; */
  min-height: calc(70vh - 180px);
  padding: ${props => (props.styleType === "pricing" ? "5vh" : "10vh")} 5vw
    ${props => (props.styleType === "pricing" ? "20vh" : "50px")};

  ${mq("l")} {
    min-height: "none";
    padding: 45px 35px
      ${props => (props.styleType === "pricing" ? "20vh" : "50px")};
  }

  ${mq("s")} {
    padding: 0 20px 50px;
    margin-top: -20px;
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: ${props =>
    props.alignOn === "left" ? "space-between" : "center"};
  padding: 0 0 2.1vw;
  width: 100%;

  ${mq("m")} {
    flex-direction: column-reverse;
  }

  .content {
    max-width: 850px;
    text-align: ${props => (props.alignOn === "left" ? "left" : "center")};

    ${fs(20)}

    ${mq("l")} {
      max-width: 650px;
    }

    ${mq("m")} {
      max-width: none;
    }
  }

  .visual {
    position: relative;
    max-width: 50%;
    opacity: 1;
    --sal-delay: 1.5s;
    cursor: none;

    ${mq("l")} {
      max-width: 40%;
    }

    ${mq("m")} {
      max-width: none;
      /* padding-bottom: 15%; */
    }

    img,
    .video-wrapper {
      display: block;
      object-fit: contain;
      transform: translate(15%, 15%) scale(1.3);
      margin-top: -3vw;
      width: 100%;
      max-width: 100%;
    }

    .video-wrapper {
      position: relative;
      width: 88%;
      border-radius: 4px;
      overflow: hidden;
      box-shadow: 0px 9px 70px rgba(7, 13, 28, 0.70);
      --sal-delay: 1.5s;
      transition: all .8s ease-in;
      margin-left: 0;
      opacity: .999;

      &.before-loaded {
        margin-left: 50%;
        opacity: 0;
      }

      ${mq("m")} {
        width: 100%;
        transform: none;
        margin-top: 0;
      }

      video {
        position: relative;
        display: block;
        width: 100%;
        z-index: 5;
        margin: -3px 0;
      }

      .top-image {
        display: block;
        position: relative;
        /* position: absolute;
        top: 0;
        left: 0;
        right: 0; */
        width: 100%;
        z-index: 10;
        margin-top: 0;
        margin-bottom: 0;
        transform: none;
        background: ${variables.colors.backgroundDark};

        img {
          margin-top: 0;
          transform: scale(1);
        }
      }

      
    }

    

    .video-cursor {
      display: block;
      line-height: 50px;
      text-align: center;
      position: absolute;
      z-index: 99999;
      transform: translate(-50%, -50%);
      pointer-events: none;
      opacity: 0;
      ${fs(13)}
      ${screenSize("width", 130)}
      ${screenSize("height", 130)}

      img {
        object-fit: contain;
        display: block;
        transform: none;
        margin: 0;
        width: 100%;
        height: 100%;
      }

      ${mq("m")} {
        opacity: 1 !important;
        top: 50% !important;
        left: 50% !important;
      }
    }

    + .content {
      max-width: 45%;
      padding-left: 7%;
      padding-right: 2%;
      padding-top: 5vw;

      ${mq("l")} {
        max-width: 60%;
      }

      ${mq("m")} {
        max-width: none;
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  .title {
    margin: 0 0 2.1vw;
    font-family: 'Bossa', sans-serif;
    font-weight: 700;
    line-height: 1.14;
    color: ${props =>
      props.dark ? variables.colors.white : variables.colors.backgroundDark};

    ${fs(46)}

    ${mq("xl")} {
      margin: 0 0 20px;
    }

    ${mq("m")} {
      margin: 0 0 20px;

      ${fs(36)}
    }

    ${screenSize("margin-bottom", 30)}
  }

  .text {
    max-width: ${props => (props.alignOn === "left" ? "80%" : "100%")};
    margin: ${props => (props.alignOn === "left" ? "0" : "0 auto")};
    color: ${props =>
      props.dark ? variables.colors.white : variables.colors.backgroundDark};
    ${props =>
      props.styleType === "pricing" ? `color: ${variables.colors.grey};` : null}

    ${mq("m")} {
      max-width: none;
      margin-bottom: 40px;
    }

    p {
      color: ${props =>
        props.dark ? variables.colors.white : variables.colors.backgroundDark};
      ${props =>
        props.styleType === "pricing"
          ? `color: ${variables.colors.grey};`
          : null}
      margin-top: 1vw;

      ${fs(20)}

      ${mq("m")} {
        margin-top: 20px;
      }

      &:first-child {
        ${screenSize("margin-bottom", 40)}
      }
    }
  }
`
