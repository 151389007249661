import PropTypes from 'prop-types';
import React from 'react';

import { Container, Content } from './styled.js';

const FeatureDetail = (props) => {
  return (
    <Container {...props}>
      <Content {...props}>
        {props.children}
      </Content>
    </Container>
  )
}

FeatureDetail.propTypes = {
  dark: PropTypes.bool
}

FeatureDetail.defaultProps = {
  siteTitle: ``,
}

export default FeatureDetail;
